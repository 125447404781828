<template>
  <div class="apply">
    <div class="container">
      <van-cell-group >
        <van-cell
          :border="false"
          icon="clock-o"
          title="资产编号"
          :value="detail.serNum"
        />
        <van-cell
          :border="false"
          icon="label-o"
          title="资产分类"
          :value="detail.detailName"
        />
        <van-cell :border="false" icon="idcard" title="资产名称" :value="detail.assetName" />
        <van-cell
          :border="false"
          icon="location-o"
          title="资产状态"
          :value="detail.statusName"
        />
      </van-cell-group>
      <van-form @submit="onSubmit" :show-error-message="false">
        <van-field
          :readonly="isScan"
          v-model="form.userName"
          label="借用人"
          name="userName"
          placeholder="请输入借用人"
          :rules="[{ required: true, message: '请输入借用人' }]"
        />
        <van-field
          :readonly="isScan"
          v-model="form.phone"
          label="借用人手机号"
          name="phone"
          placeholder="请输入借用人手机号"
          :rules="[{ pattern:/^1[3-9]\d{9}$/, required: true, message: '请输入借用人手机号'}]"
        />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >确认</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { getOfficeassetDetail, officeassetBorrow } from "@/api/asset";
import { Button, Image, Cell, CellGroup, Form, Field,Toast} from "vant";
import { mapState } from "vuex";
export default {
  name: "apply",
  components: {
    [Button.name]: Button,
    [Image.name]: Image,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Form.name]: Form,
    [Field.name]: Field,
  },
  computed:{
    queryId(){
      return this.$route.query.id
    },
    isScan(){
      return this.$route.query.type === 'scan'
    },
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  data(){
    return {
      detail:{},
      form:{
        userName:'',
        phone:''
      }
    }
  },
  mounted() {
    this.getOfficeassetDetail()
    if(this.isScan) {
      this.form.userName=this.userInfo.nick_name
      this.form.phone=this.userInfo.account
    }
  },
  methods: {
    async getOfficeassetDetail(){
      const res = await getOfficeassetDetail(this.queryId)
      // console.log(res)
      this.detail = res.data
    },
    async onSubmit(form){
      console.log(form)
      const params = {
        assetId: this.detail.id,
        serNum:this.detail.serNum,
        userName:this.form.userName,
        phone:this.form.phone
      }
      const res = await officeassetBorrow({...params})
      // console.log(res)
      Toast(res.msg)
      setTimeout(() => {
        this.$router.push('/')
      }, 500);
    }
  },
};
</script>

<style lang="scss" scoped>
.van-cell-group,
.van-cell {
  background: transparent;
}
.apply {
  overflow: hidden;
  .container {
    width: 343px;
    height: 500px;
    margin: 12px auto;
    background: #ffffff;
    border-radius: 5px;
  }
}
</style>