import request from '../common/request';

// 设备借用
export const officeassetBorrow = (params) => request({
  url: '/api/blade-asset/officeasset/front/borrow',
  method: 'post',
  data:{
    ...params
  }
});

// 设备归还
export const officeassetRepay = (params) => request({
  url: '/api/blade-asset/officeasset/front/repay',
  method: 'post',
  data:{
    ...params
  }
});

// 设备列表
export const getOfficeassetPage = (params) => request({
  url: '/api/blade-asset/officeasset/front/page',
  method: 'get',
  params:{
    ...params
  }
});

// 设备列表1
export const getOfficeassetList = (params) => request({
  url: '/api/blade-asset/officeasset/front/getList',
  method: 'get',
  params:{
    ...params
  }
});

// 设备详情
export const getOfficeassetDetail= (id) => request({
  url: `/api/blade-asset/officeasset/front/${id}`,
  method: 'get',
});